import React from 'react';
import PropTypes from 'prop-types';

function SignatureElement({
  participantId, label, name, obligation, jurisdiction, representative,
}) {
  if (obligation === 'legal') {
    return (
      <div className="signature" data-participant={participantId}>
        {/* <a href="#" data-action="remove"><i className="fa fa-fw fa-times"></i></a> */}
        <div className="signature-image">
          <span participant-label={label}>{label}</span>
          <span participant-name={name}>{name}</span>
          <img className="image-object" src="" alt="signature" />
        </div>
        <span participant-representative={representative}>{representative}</span>
        {['mx', 'MX'].includes(jurisdiction) && <span>Representante Legal</span>}
      </div>
    );
  }

  return (
    <div className="signature" data-participant={participantId}>
      {/* <a href="#" data-action="remove"><i className="fa fa-fw fa-times"></i></a> */}
      <div className="signature-image">
        <img className="image-object" src="" alt="signature" />
      </div>
      <span participant-name={name}>{name}</span>
      <span participant-label={label}>{label}</span>
    </div>
  );
}

SignatureElement.propTypes = {
  participantId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  obligation: PropTypes.string.isRequired,
  jurisdiction: PropTypes.string.isRequired,
  representative: PropTypes.string,
};

export default SignatureElement;
