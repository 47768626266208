/* global fbq */
import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Modal, ModalHeader,
  ModalBody, Input, InputGroupAddon,
  Spinner, InputGroup, FormFeedback,
  InputGroupText, Form, FormGroup, Label,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import config from '../config';


const SignUpSchema = Yup.object().shape({
  givenName: Yup.string().required('Obligatorio'),
  familyName: Yup.string().required('Obligatorio'),
  email: Yup.string()
    .email('Correo incorrecto')
    .required('Obligatorio'),    
  password: Yup.string()
    .min(8, 'La contraseña debe de tener un mínimo de 8 caracteres')
    .matches(/[a-z]/, 'La contraseña debe de tener al menos una letra minúscula')
    .matches(/[A-Z]/, 'La contraseña debe de tener al menos una letra mayúscula')
    .required('Obligatorio'),
  confirmPassword: Yup.string()
    .required('Obligatorio')
    .min(8, 'La contraseña debe de tener un mínimo de 8 caracteres')
    .matches(/[a-z]/, 'La contraseña debe de tener al menos una letra minúscula')
    .matches(/[A-Z]/, 'La contraseña debe de tener al menos una letra mayúscula'),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Correo incorrecto')
    .required('Required'),    
  password: Yup.string()
    .required('Required'),
});

function SignupForm({
  type = 'demo',
  formik: {
    isSubmitting, errors, touched, values, handleChange, handleSubmit,
  },
  goToLogin,
  formButtonLabel,
}) {
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);

  function onSubmit() {
    handleSubmit();
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="givenName">
            {t('demoForm.name_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="givenName"
              type="text"
              name="givenName"
              placeholder={t('demoForm.name_label')}
              value={values.givenName}
              invalid={errors.givenName && touched.givenName}
              onChange={handleChange}
            />
            <FormFeedback>{errors.givenName}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="familyName">
            {t('demoForm.last_name_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="familyName"
              type="text"
              name="familyName"
              placeholder={t('demoForm.last_name_label')}
              value={values.familyName}
              invalid={errors.familyName && touched.familyName}
              onChange={handleChange}
            />
            <FormFeedback>{errors.familyName}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="email">
            {t('demoForm.email_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="email"
              type="email"
              name="email"
              placeholder={t('demoForm.placeholder2')}
              value={values.email}
              invalid={errors.email && touched.email}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold" for="password">
            {t('demoForm.password_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="password"
              name="password"
              type="password"
              placeholder={t('demoForm.placeholder12')}
              value={values.password}
              invalid={errors.password && touched.password}
              onChange={handleChange}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold" for="confirmPassword">
            {t('demoForm.confirm_password_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder={t('demoForm.placeholder12')}
              value={values.confirmPassword}
              invalid={errors.confirmPassword && touched.confirmPassword}
              onChange={handleChange}
            />
            <FormFeedback>{errors.confirmPassword}</FormFeedback>
          </InputGroup>
        </FormGroup>
      </Form>
      <div className="text-center">
        <button type="submit" onClick={onSubmit} className="button orange animate-up w-100" disabled={isSubmitting}>
          {t('Crear cuenta')}
        </button>
        <div className="hover_text-decoration-undeline p-1 text-muted cursor-pointer" onClick={goToLogin} role="button">Iniciar sesion</div>
      </div>
    </>
  );
}

function LoginForm({
  type = 'demo',
  formik: {
    isSubmitting, errors, touched, values, handleChange, handleSubmit,
  },
  goToSignUp,
  formButtonLabel,
}) {
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);

  function onSubmit() {
    handleSubmit();
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="email">
            {t('demoForm.email_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="email"
              type="email"
              name="email"
              placeholder={t('demoForm.placeholder2')}
              value={values.email}
              invalid={errors.email && touched.email}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold" for="password">
            {t('demoForm.password_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="password"
              name="password"
              type="password"
              placeholder={t('demoForm.placeholder12')}
              value={values.password}
              invalid={errors.password && touched.password}
              onChange={handleChange}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </InputGroup>
        </FormGroup>
      </Form>
      <div className="text-center">
        <button type="submit" onClick={onSubmit} className="button orange animate-up w-100" disabled={isSubmitting}>
          {t('Entrar')}
        </button>
        <div className="hover_text-decoration-undeline p-1 text-muted cursor-pointer" onClick={goToSignUp} role="button">Crear Cuenta</div>
      </div>
    </>
  );
}

const SignUpModalContainer = (props) => {
  const {
    buttonLabel,
    className,
    publicTemplateId,
    publicTemplateName,
    onlyForm,
    size = 'md',
    initialType = 'signup',
    inverted,
    buttonClassName = 'button orange animate-up mb-2 mr-1',
    formButtonLabel,
  } = props;
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const [type, setType] = useState(initialType);
  const host = 'https://enterprise.api.trato.io';
  const starterHost = 'https://starter.trato.io';
  // const host = 'http://localhost:4000';
  // const starterHost = 'http://localhost:3000';

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      givenName: '',
      familyName: '',
    },
    onSubmit: async (values, actions) => {

      if (type === 'signup' && values.password !== values.confirmPassword) {
        actions.setFieldError('password', 'Las contraseñas deben coincidir');
        actions.setFieldError('cofirmPassword', 'Las contraseñas deben coincidir');
      } else {
        values.from = {
          landing: true,
          starter: false,
          publicTemplate: publicTemplateId,
        };
        if (type === 'signup') await signup(values, actions);
        else await login(values);

        // actions.resetForm({
        //   email: '',
        //   password: '',
        //   confirmPassword: '',
        // });
      }

      actions.setSubmitting(false);
      // const sent = await sendMessage(values);

      
    },
    validationSchema: type === 'login' ? LoginSchema : SignUpSchema,
  });

  const toggle = () => {
    formik.resetForm();
    setIsOpen(open => !open);
    setType('signup')
  };

  const signup = async (values, actions) => {
    
    const response = await fetch(`${host}/auth/signup`, {
      method: 'POST',
      headers: { 
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      // toast.success('Te enviamos un correo para verificar la cuenta')
      // setIsOpen(false);
      actions.resetForm({
        email: '',
        password: '',
        confirmPassword: '',
      });
      // setType('login');
     
      setSucceed(true)
    } else {
      const json = await response.json();
      toast.error(t(`errors.${json.error}`, 'Error en el envio.'), {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'error',
      });
    }
  };
  const login = async (values) => {
    const response = await fetch(`${host}/auth/login`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      // toast.success('Redirect')
      setIsOpen(false);
      const json = await response.json();
      const { data } = json;
      // setType('login');
      const url = redirectTo(starterHost, data.domain, data.challengeParameters.session, data.challengeParameters.username)

      window.open(`${url}&publicTemplateName=${publicTemplateName}`)
      // // setSucceed(true)
    } else {
      const json = await response.json();
      toast.error(t(`errors.${json.error}`, 'Error al iniciar sesion.'), {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'error',
      });
    }
  };
  const handleResendVerificationCode = async () => {
    const response = await fetch(`${host}/auth/verify-account/resend`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: formik.values.email }),
    });

    if (response.ok) {
      // toast.success('Redirect')
 
    } else {
      const json = await response.json();
      toast.error(t(`errors.${json.error}`, 'Error al reenviar correo.'), {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'error',
      });
    }
  };



  return (
    <>
      <Button className={classnames(`${buttonClassName}`, { inverted })} size={size} onClick={toggle}> {buttonLabel || 'Crear cuenta'}</Button>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader className="text-center signup-modal--header" toggle={toggle}>
          {t('Regístrate, es fácil y gratis')}
          <small className="d-block text-muted">{t('Completa el formulario para enviar el documento')} </small>
        </ModalHeader>
        <ModalBody className="py-2 px-5">
          {
            type === 'login' ? 
              <LoginForm formik={formik} formButtonLabel={formButtonLabel} goToSignUp={() => setType('signup')} />
              : succeed ? (
                            <div className="media-body">
                                <div className="text-center">
                                    <h4 className="font-weight-bold mb-4 text-center">{t('demoForm.verifyAccountBody1')}</h4>
                                    <div>
                                        <span className="text-center text-muted mb-4 text-center">{t('demoForm.verifyAccountBody2', { email: formik.values.email })}</span>
                                    </div>
                                    {/* <div>
                                        <img className="m-1 mb-2 p-1" src="/images/file-sent.png" style={{width: 120}} />
                                    </div> */}
                                    <div className="p-2"><strong>¿No lo has recibido?</strong></div>
                                    <button data-action="signup" type="button" className="btn btn-sm btn-primary" onClick={handleResendVerificationCode}>{t('demoForm.resendVerify')}</button>
                                    <div className="hover_text-decoration-undeline p-1 text-muted" onClick={() => setType('login')} role="button">Iniciar sesion</div>
                                </div>
                            </div>
                        ) : ( 
                      <SignupForm formik={formik} formButtonLabel={formButtonLabel} goToLogin={() => setType('login')} />
                      )
              
          }
          
          
        </ModalBody>
      </Modal>
    </>
  );
};

export function InputWithLoader({ loading = false, disabled, ...props }) {
  return (
    <>
      <Input
        {...props}
        disabled={disabled || loading}
      />
      {loading && (
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <Spinner
            type="border"
            color="secondary"
            role="status"
            style={{ borderWidth: '1.5px', width: '1rem', height: '1rem' }}
              />
        </InputGroupText>
      </InputGroupAddon>
      )}
    </>
  );
}

export function checkTratoDomain(domain) {
  return ['freemium', 'starter', 'enterprise', 'pyme', 'pro', 'enterprise-test', 'enterprise-stage', 'team'].includes(domain);
}
export function checkCLMDomain(domain) {
  return ['freemium', 'enterprise', 'pyme', 'pro', 'enterprise-test', 'enterprise-stage', 'team'].includes(domain);
}

export function redirectTo(host, domain = '', jwt = '', username = '') {
  const isTratoDomain = checkTratoDomain(domain)
  const isCLMDomain = checkCLMDomain(domain)
  if (domain === 'compusoluciones_partner') {
    domain = 'compusoluciones'
  }
  const isInCorrectDomain = window.location.href.includes(isTratoDomain ? 'starter' : domain);
  const isDev = process.env.NODE_ENV === 'development' || window.location.href.includes('starter-test');
  // const isDev = false;
  if (!isInCorrectDomain && !isDev) {
    localStorage.removeItem('auth');
  }
  if (isCLMDomain) {
    return `${host}/auth/redirect?token=${jwt}&username=${username}`
  }
  if (!isTratoDomain && !isInCorrectDomain && !isDev) {
    return `https://${domain}.trato.io/auth/redirect?token=${jwt}&username=${username}`
  } else if (isTratoDomain && !isInCorrectDomain && !isDev) {
    // return `https://starter.trato.io/auth/redirect?token=${jwt}&username=${username}`
    return `${host}/auth/redirect?token=${jwt}&username=${username}`
  }
}

SignupForm.propTypes = {
  type: PropTypes.string,
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    errors: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.any,
  }),
  formButtonLabel: PropTypes.string,
};

SignUpModalContainer.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onlyForm: PropTypes.bool,
  size: PropTypes.string,
  inverted: PropTypes.any,
  buttonClassName: PropTypes.string,
  formButtonLabel: PropTypes.string,
};

InputWithLoader.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};


export default SignUpModalContainer;
