import React from 'react';
import PropTypes from 'prop-types';

const defaultParticipants = [
  {
    obligation: 'individual',
    name: '',
    last_name: '',
    representative: '',
    label: 'La Parte',
  },
  {
    obligation: 'individual',
    name: '',
    last_name: '',
    representative: '',
    label: 'La Contraparte',
  },
];


function HeaderElement({
  type = '', participants = defaultParticipants, isStatic, children,
}) {
  if (isStatic) {
    return (
      <p className="text-justify">
        <strong>CONTRATO</strong>  {' '}
        QUE CELEBRAN {' '}
        {
          participants?.map((participant, i) => {
            if (participant.obligation === 'individual') {
              return (
                <React.Fragment key={participant._id}>
                  <strong>{participant?.name?.toUpperCase() } {participant?.last_name?.toUpperCase()}</strong>
                  , A QUIEN EN LO SUCESIVO SE LE DENOMINARA COMO <strong>"{participant?.label?.toUpperCase()}"</strong>
                  {participants?.length - 2 === i && ' Y '}
                </React.Fragment>
              );
            }

            if (participant.obligation === 'legal') {
              return (
                <React.Fragment key={participant?._id}>
                  <strong>{participant?.name}</strong>, REPRESENTADA EN ESTE ACTO POR {' '}
                  <strong>{ participant?.representative }</strong>, A QUIEN EN LO SUCESIVO SE LE DENOMINARA COMO {' '}
                  <strong>"{ participant?.label }"</strong>
                  {participants?.length - 2 === i && ' Y '}
                </React.Fragment>
              );
            }
          })
        }
        ; AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:
      </p>
    );
  }

  return (
    <>{children}</>
  );
}

HeaderElement.propTypes = {
  participants: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  isStatic: PropTypes.bool,
};

export default HeaderElement;
