import React, { useMemo } from 'react';

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from '../layouts/index';

import DemoForm from '../components/DemoForm';
import { ContractPreview } from '../components/ContractPreview';
import SEO from '../components/SEO';
import SignUpModal from '../components/SignUpModal';
import Img from 'gatsby-image';
function TemplateDetail({ data, locale, pageContext: { id }, location }) {
  const { template: templateJSON, name, useNewFlow } =
    data.publicTemplates || templateJSON;
  const post = data.wordpressPost || {};

  const template = useMemo(() => {
    const totalElements = templateJSON.elements.length;
    const totalElementsToShow = Math.max(Math.ceil(totalElements * 0.5), 10);

    return {
      ...templateJSON,
      elements: [
        ...templateJSON.elements.slice(0, totalElementsToShow),
        {
          type: 'text',
          content:
            '<div class="text-center" style="font-size: 35px !important;">...<div>',
        },
        // templateJSON.elements.find(el => el.type === 'signature'),
      ],
    };
  }, [templateJSON]);

  console.log({ template });
  console.log(data);
  return (
    <Layout
      id="landing"
      locale={locale}
      bodyClass="page-home landing"
      inverted
      border
      location={location}
    >
      <SEO title={post?.title} />
      <section className="bg-grey py-0 border-top border-bottom">
        <Container className="mt-10">
          <Row>
            <Col lg={5} className="d-flex">
              <div className="align-self-center">
                <h1 className="font-weight-bold mb-2">{post?.title}</h1>
                {useNewFlow ? (
                  <Button
                    tag="a"
                    href={`https://starter.trato.io/public-templates/${id}/documents/create`}
                    target="_blank"
                    color="orange"
                    className="animate-up text-light"
                  >
                    Utilizar plantilla
                  </Button>
                ) : (
                  <SignUpModal
                    buttonLabel="Utilizar plantilla"
                    publicTemplateId={id}
                    publicTemplateName={name}
                  />
                )}
              </div>
            </Col>
            <Col lg={7} className="text-center">
              {/* <img
                src={image || TemplatePortrait}
                className="img-fluid w-75 mx-auto mt-4"
                width={476.25}
                height={338.31}
                loading={'eager'}
                alt={template.name}
              /> */}
              <Img
                className="mt-4"
                alt={template.name}
                style={{ maxWidth: '460px', margin: '0px auto' }}
                loading="eager"
                fluid={
                  data.allImage?.edges?.[0]?.node.image.childImageSharp.fluid
                }
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-0 border-bottom">
        <Container fluid>
          <Row noGutters>
            <Col lg={7} className="bg-light py-5">
              <Container className="px-5">
                <div className="blog-content template-content">
                  {parse(post?.content ?? '')}
                </div>
              </Container>
            </Col>
            <Col lg={5} className="bg-grey-x">
              <Container className="px-5">
                <Card className="bg-light mt-5 mb-3 rounded-0 shadow-sm">
                  <CardHeader className="py-2 px-4 border-bottom w-100">
                    <h5 className="font-weight-bold text-center mb-0">
                      {template.name}
                    </h5>
                  </CardHeader>
                  <CardBody className="py-1 contract-preview">
                    <ContractPreview contract={template} />
                  </CardBody>
                </Card>
                <div className="text-center">
                  {useNewFlow ? (
                    <Button
                      tag="a"
                      href={`https://starter.trato.io/public-templates/${id}/documents/create`}
                      target="_blank"
                      color="orange"
                      className="animate-up text-light"
                    >
                      Utilizar plantilla
                    </Button>
                  ) : (
                    <SignUpModal
                      buttonLabel="Utilizar plantilla"
                      publicTemplateId={id}
                      publicTemplateName={name}
                    />
                  )}
                  {/* <Button tag="a" href={`https://starter.trato.io/signup?publicTemplateId=${id}`} target="_blank" color="orange" className="animate-up text-light"></Button> */}
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-grey py-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="card bg-light my-5 rounded-0 shadow-sm">
                <div className="card-body p-3 w-100">
                  <div className="row">
                    <div className="col-md-8">
                      <h3 className="mb-1 font-weight-bold">
                        Solicita una demo ahora
                      </h3>
                      <p className="text-muted mb-0">
                        Prueba <strong className="text-primary">Trato</strong> y
                        transforma la gestión de tus contratos en un proceso
                        eficiente.
                      </p>
                    </div>
                    <div className="col-md-3 offset-md-1 d-flex">
                      <DemoForm className="align-self-center" type="template" />
                      {/*   <a href="https://pipedrivewebforms.com/form/6ac4c4d58ceeb7fcbc63c8bb55e7911f5662609" target="_blank" className="button orange animate-up d-inline-block align-self-center" >Solicita una demo</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

// TemplateDetail.propTypes = {
//   // data: PropTypes.shape({
//   //   suce: PropTypes.string,
//   //   _id: PropTypes.string,
//   //   elements: PropTypes.arrayOf({
//   //     content: PropTypes.string,
//   //   })
//   // }),
//   locale: PropTypes.string,
//   pageContext: PropTypes.shape({
//     publicTemplate: PropTypes.shape({
//       name: PropTypes.string,
//       _id: PropTypes.string,
//     }),
//   }),
// };

export default TemplateDetail;

export const templateQuery = graphql`
  query($id: String!, $templateId: String!, $imageUrl: String!) {
    publicTemplates(template: { _id: { eq: $templateId } }) {
      image
      name
      jurisdiction
      useNewFlow
      template {
        elements {
          type
          content
          index
          number
          staticfield
          _id
        }
        name
        _id
        participants {
          last_name
          name
          obligation
          email
          representative
          label
          _id
        }
      }
    }
    wordpressPost(tags: { elemMatch: { name: { eq: $id } } }) {
      id
      title
      content
    }
    allImage(filter: { url: { eq: $imageUrl } }) {
      edges {
        node {
          id
          url
          image {
            childImageSharp {
              id
              fluid(maxWidth: 915, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            name
          }
        }
      }
    }
  }
`;
