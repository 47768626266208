import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SignatureElement from './SignatureElement';

import HeaderElement from './HeaderElement';

export function getDisplayName(name = '', lastName = '') {
  if (!lastName || !lastName.length) return name;

  return `${name} ${lastName}`;
}


function renderElements(elements, participants, jurisdiction) {
  if (!elements || !elements.length) {
    return null;
  }

  return elements.map((element, i) => (
    <ContractElement key={element._id} index={i} participants={participants} jurisdiction={jurisdiction} {...element} />
  ));
}

export function ContractPreview({ contract }) {
  const elements = useMemo(() => renderElements(contract.elements, contract.participants, contract.jurisdiction), [contract]);

  return (
    <div className="p-4 elements-container" style={{userSelect: 'none'}}>
      {elements}
    </div>
  );
}


function ContractElement({
  type, content, participants, jurisdiction, number, index,
}) {
  if (type === 'break') {
    return <div className="page-break" />;
  }

  const getOrdinalNumber = (number) => {
    if (!isNaN(number)) {
      const num = String(number);
      const units = ['primera', 'segunda', 'tercera', 'cuarta', 'quinta', 'sexta', 'séptima', 'octava', 'novena'];
      const dozens = ['décima', 'vigésima', 'trigésima', 'cuadragésima', 'quincuagésima', 'sexagésima', 'septuagésima', 'octogésima', 'nonagésima'];
      const hundreds = ['centésima', 'ducentésima', 'tricentésima', 'cuadringentésima', 'quingentésima', 'sexcentésima', 'septingentésima', 'octingentésima', 'noningentésima'];
      const array = [units, dozens, hundreds];
      const word = [];
      let index = 0;
      for (let i = num.length; i > 0; i--) {
        const ordinalText = array[index][Number(num[i - 1]) - 1];
        if (ordinalText) {
          word.unshift(ordinalText);
        }

        index++;
      }

      return word?.join(' ')?.toUpperCase();
    }

    return '';
  };

  const renderLogo = () => <div dangerouslySetInnerHTML={{ __html: content }} />;

  const renderHeader = isStatic => (
    <div className="header-text contenteditable">
      <HeaderElement participants={participants} type={type} isStatic={isStatic}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </HeaderElement>
    </div>
  );
  const renderTitle = () => (
    <div className="simple-title contenteditable">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderText = () => (
    <div className="simple-text contenteditable">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderTable = () => (
    <div className="simple-text contenteditable">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderStatement = () => (
    <div className="statement-text contenteditable">
      <div className="statement-title"> {number || index}.</div><div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderClause = () => (
    <div className="clause-text contenteditable">
      <div className="clause-title">{number ? getOrdinalNumber(number) : getOrdinalNumber(index)}.</div><div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderDocumentFooter = () => (
    <div id="pageFooter">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderDocumentHeader = () => (
    <div id="pageHeader">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

  const renderSignature = () => <>
    <div className="signature-content">
      {participants && participants?.map(participant => (
        <React.Fragment key={participant._id}>{
                content.includes(participant._id)
                    && <SignatureElement
                        participantId={participant._id}
                        label={participant.label}
                        name={participant.obligation === 'legal' ? getDisplayName(participant.name) : getDisplayName(participant.name, participant.last_name)}
                        representative={participant.representative}
                        obligation={participant.obligation}
                        jurisdiction={jurisdiction}
                    />
                }
        </React.Fragment>
      ))}
    </div>
                                </>;

  return (
    <div className="element">
      <div className="element-content">
        {type === 'staticheader' && renderHeader(false)}
        {type === 'header' && renderHeader(true)}
        {type === 'document_logo' && renderLogo()}
        {type === 'title' && renderTitle()}
        {type === 'text' && renderText()}
        {type === 'table' && renderTable()}
        {type === 'statement' && renderStatement()}
        {type === 'clause' && renderClause()}
        {type === 'signature' && renderSignature()}
        {type === 'document_footer' && renderDocumentFooter()}
        {type === 'document_header' && renderDocumentHeader()}
      </div>
    </div>
  );
}
